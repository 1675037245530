<template>
  <div>
    <a-modal
      centered
      bordered
      v-model="visible"
      :width="450"
      title="新增职务"
      :destroyOnClose="true"
      :bodyStyle="{ padding: 0 }"
      :ok-button-props="{ props: { loading: loading } }"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-card :bordered="false" :body-style="{ padding: '20px 20px 0 20px' }">
        <a-form-model ref="form" :model="dataSource" :labelCol="{ span: 8 }" :wrapperCol="{ span: 12 }">
          <a-form-model-item
            label="职务名称："
            prop="name"
            :rules="{
              required: true,
              message: '请输入内容',
              trigger: 'blur',
            }"
          >
            <a-input v-model.trim="dataSource.name" :maxLength="25" />
          </a-form-model-item>
          <a-form-model-item
            label="职务描述："
            prop="describe"
            :rules="{
              required: true,
              message: '请输入',
              trigger: 'blur',
            }"
          >
            <a-input
              v-model.trim="dataSource.describe"
              :maxLength="500"
              :autoSize="{ minRows: 4, maxRows: 4 }"
              type="textarea"
            />
          </a-form-model-item>
          <a-form-model-item label="角色：">
            <a-select
              mode="multiple"
              :default-value="dataSource.roleSet"
              v-model="dataSource.roleSet"
              allowClear
              placeholder="请选择"
            >
              <a-select-option v-for="roleInfo in roleOptions" :key="roleInfo.id" :value="roleInfo.id">{{
                roleInfo.roleName
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label=" 状态："
            prop="status"
            :rules="{
              required: true,
              message: '请选择状态',
              trigger: 'blur',
            }"
          >
            <a-radio-group v-model="dataSource.status">
              <a-radio v-for="statusInfo in statusOptions" :key="statusInfo.value" :value="statusInfo.value">
                {{ statusInfo.label }}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-form-model>
      </a-card>
    </a-modal>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import * as postApi from '@/api/post'
import * as roleApi from '@/api/role'
import { COMMON_STATUS } from '@/store/dictionary'
export default {
  data() {
    return {
      visible: false,
      loading: false,
      roleOptions: [],
      statusOptions: COMMON_STATUS,
    }
  },
  props: {
    dataSource: {
      type: Object,
      default: function () {
        return {
          name: '',
          describe: '',
          roleSet: [],
          status: '',
        }
      },
    },
  },
  mounted() {
    roleApi.roleOptions().then((res) => {
      this.roleOptions = res.data
    })
  },
  methods: {
    showModal(callback) {
      this.visible = true
      this.callback = callback
    },
    validate() {
      const {
        $refs: { form },
      } = this
      return new Promise((resolve, reject) => {
        form.validate((valid, err) => {
          if (valid) {
            resolve(valid)
            return
          }
          reject(err)
        })
      })
    },
    formatForm() {
      try {
        const ret = cloneDeep(this.dataSource)
        return { ...ret }
      } catch {}
    },
    handleOk() {
      const { callback } = this
      const { $notification, validate } = this
      validate().then((valid) => {
        if (valid) {
          this.loading = true
          try {
            const dataSource = this.formatForm()
            if (dataSource.id) {
              postApi
                .update({ ...dataSource })
                .then((res) => {
                  $notification['success']({ message: '操作成功' })
                  callback()
                  this.callback = null
                  this.visible = false
                })
                .finally(() => (this.loading = false))
            } else {
              postApi
                .create({ ...dataSource })
                .then((res) => {
                  $notification['success']({ message: '操作成功' })
                  callback()
                  this.callback = null
                  this.visible = false
                })
                .finally(() => (this.loading = false))
            }
          } catch {
            $notification['error']({ message: '提示', description: '脚本错误' })
            this.loading = false
          }
        }
      })
    },
    handleCancel() {},
  },
}
</script>

<style lang="less" scoped></style>

import request from '@/utils/request'
const postApi = {
  list: '/post',
  create: '/post',
  update: id => `/post/${id}`,
  detail: id => `/post/${id}`,
  remove: id => `/post/${id}`,
  postOptions: '/post/all'
}
export function list(parameter) {
  return request({
    url: postApi.list,
    method: 'get',
    params: parameter
  })
}
export function create(parameter) {
  return request({
    url: postApi.create,
    method: 'post',
    data: parameter
  })
}
export function update(parameter) {
  const { id } = parameter
  delete parameter.id
  return request({
    url: postApi.update(id),
    method: 'put',
    data: parameter
  })
}
export function detail(parameter) {
  return request({
    url: postApi.detail(parameter),
    method: 'get'
  })
}
export function remove(parameter) {
  return request({
    url: postApi.remove(parameter),
    method: 'delete'
  })
}
export function postOptions() {
  return request({
    url: postApi.postOptions,
    method: 'get'
  })
}

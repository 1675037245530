<template>
  <div>
    <a-card :bordered="false" style="margin-bottom: 24px">
      <a-row :gutter="[24, 0]">
        <a-form-model class="ant-advanced-search-form">
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-model-item label="职务名称：">
              <a-input-search v-model="query.name" placeholder="请输入" style="width: 100%" @search="initData" />
            </a-form-model-item>
            <!--            mechanismId-->
          </a-col>
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-model-item label="所属机构：" prop="mechanismId">
              <a-cascader
                :show-search="{ filter }"
                :options="mechanismOptions"
                change-on-select
                v-model="defaultMechanism"
                @change="onChange"
                :fieldNames="{ label: 'name', value: 'id', children: 'childrenList' }"
                placeholder="请选择"
              >
              </a-cascader>
            </a-form-model-item>
          </a-col>
        </a-form-model>
      </a-row>
    </a-card>
    <a-card :body-style="{ padding: 0 }">
      <div slot="title">
        <span>职务列表</span>
      </div>
      <div slot="extra">
        <a-button v-if="$auth('post_add')" type="primary" shape="round" @click="onAdd"
          ><a-icon type="plus" />新增职务</a-button
        >
      </div>
      <main-table ref="table" @edit="onEdit"></main-table>
    </a-card>
    <Model ref="model" :dataSource="modalData" />
  </div>
</template>

<script>
import MainTable from './components/Table'
import Model from './components/Model'
import * as postApi from '@/api/post'
import * as mechanismApi from '@/api/mechanism'
import { cloneDeep } from 'lodash'
const modalData = {
  type: 'ADD',
  name: '',
  describe: '',
  roleSet: [],
  status: '',
}
export default {
  name: 'PermissionPostList',
  data() {
    return {
      query: { name: '', mechanismId: '' },
      modalData: {},
      mechanismOptions: [],
      defaultMechanism: [],
    }
  },
  components: {
    MainTable,
    Model,
  },
  activated() {
    if (this.ready) {
      this.initData()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true
    })
    mechanismApi.mechanismOptions().then((res) => {
      this.mechanismOptions = res.data
    })
    this.initData()
  },
  methods: {
    initData() {
      this.$refs.table.initData(this.query)
    },
    onAdd() {
      const {
        $refs: { model },
        $nextTick,
        initData,
      } = this
      this.modalData = cloneDeep(modalData)
      $nextTick(() =>
        model.showModal((e) => {
          initData()
        })
      )
    },
    onEdit(record) {
      const {
        $refs: { model },
        $nextTick,
        initData,
      } = this
      postApi.detail(record.id).then((res) => {
        const { id, name, describe, roleVOSet, status } = res.data
        const roleSet = roleVOSet.map((v) => v.id)
        this.modalData = Object.assign(cloneDeep(modalData), { id, name, describe, roleSet, status })
        $nextTick(() =>
          model.showModal((e) => {
            initData()
          })
        )
      })
    },
    onChange(record) {
      this.defaultMechanism = record
      this.$set(this.query, 'mechanismId', record[record.length - 1])
      this.initData()
    },
  },
}
</script>

<style lang="less" scoped>
.ant-advanced-search-form /deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.ant-advanced-search-form /deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"centered":"","bordered":"","width":450,"title":"新增职务","destroyOnClose":true,"bodyStyle":{ padding: 0 },"ok-button-props":{ props: { loading: _vm.loading } }},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-card',{attrs:{"bordered":false,"body-style":{ padding: '20px 20px 0 20px' }}},[_c('a-form-model',{ref:"form",attrs:{"model":_vm.dataSource,"labelCol":{ span: 8 },"wrapperCol":{ span: 12 }}},[_c('a-form-model-item',{attrs:{"label":"职务名称：","prop":"name","rules":{
            required: true,
            message: '请输入内容',
            trigger: 'blur',
          }}},[_c('a-input',{attrs:{"maxLength":25},model:{value:(_vm.dataSource.name),callback:function ($$v) {_vm.$set(_vm.dataSource, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"dataSource.name"}})],1),_c('a-form-model-item',{attrs:{"label":"职务描述：","prop":"describe","rules":{
            required: true,
            message: '请输入',
            trigger: 'blur',
          }}},[_c('a-input',{attrs:{"maxLength":500,"autoSize":{ minRows: 4, maxRows: 4 },"type":"textarea"},model:{value:(_vm.dataSource.describe),callback:function ($$v) {_vm.$set(_vm.dataSource, "describe", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"dataSource.describe"}})],1),_c('a-form-model-item',{attrs:{"label":"角色："}},[_c('a-select',{attrs:{"mode":"multiple","default-value":_vm.dataSource.roleSet,"allowClear":"","placeholder":"请选择"},model:{value:(_vm.dataSource.roleSet),callback:function ($$v) {_vm.$set(_vm.dataSource, "roleSet", $$v)},expression:"dataSource.roleSet"}},_vm._l((_vm.roleOptions),function(roleInfo){return _c('a-select-option',{key:roleInfo.id,attrs:{"value":roleInfo.id}},[_vm._v(_vm._s(roleInfo.roleName))])}),1)],1),_c('a-form-model-item',{attrs:{"label":" 状态：","prop":"status","rules":{
            required: true,
            message: '请选择状态',
            trigger: 'blur',
          }}},[_c('a-radio-group',{model:{value:(_vm.dataSource.status),callback:function ($$v) {_vm.$set(_vm.dataSource, "status", $$v)},expression:"dataSource.status"}},_vm._l((_vm.statusOptions),function(statusInfo){return _c('a-radio',{key:statusInfo.value,attrs:{"value":statusInfo.value}},[_vm._v(" "+_vm._s(statusInfo.label)+" ")])}),1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }